export const environment = {
  production: false,
  local: false,
  name: 'Devfront',
  baseClientUrl: 'https://devfront-optimy-pwa.com',
  baseApiUrl: '',
  appVersion: require('../../package.json').version,
  appToken: '2c4af9eeb2446e23ef62e624c480abba',
  backendUrl: 'https://devfront-core-optimy.com/api/v1',
  logRocketProjectIdentification: 'optimy/agent-portal-devfront',
  firebase: {
    apiKey: 'AIzaSyD_2oNjHFHxa2u24i_ODHLUpyy8Jm10Hxc',
    authDomain: 'staging-optimy-pwa-85826.firebaseapp.com',
    databaseURL: 'https://staging-optimy-pwa-85826.firebaseio.com',
    projectId: 'staging-optimy-pwa-85826',
    storageBucket: 'staging-optimy-pwa-85826.appspot.com',
    messagingSenderId: '609977165354',
    appId: '1:609977165354:web:fe4d93ba90bbd380a10553',
    measurementId: 'G-HSDQJEBX5T'
  },
  cellcomGtmId: 'GTM-KQMJP8B',
  royfossGtmId: 'GTM-P22R98Q',
  googleGeoCodingKey: 'AIzaSyA7QU1iqE4z4fqSA_Uz8yqmC1PfctzpqbA',
  staffingTenantId: 8,
  getBuildNumberApi: 'https://lambda.devfront-optimy-pwa.com/agentstatus',
  environmentName: 'devfront',
  hyundaiTenantId: 24,
  delonghiTenantId: 31
};
